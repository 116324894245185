import React, { memo, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { v4 as uuid } from 'uuid';
import {
  COLORS,
  MODAL_SIZES,
  OPTIONS_EXPIRATION_TYPE,
  OPTIONS_ORDER_METHOD,
  WIDTH,
  OPTIONS_BUY_SELL,
  EXPIRATION_TYPE_MAIN,
  OPTIONS_CLOSE_ORDER_TYPES,
  ORDER_TYPES_MAIN,
  TRADE_METHODS,
  OPTIONS_NO_EXPIRATION_ONLY,
  FX,
  ETF,
  CFD,
  ORDER_METHOD_MAIN,
} from 'shared-modules/constants';
import {
  COUNT_INPUT,
  CLOSE_PRICE_INPUT,
  CLOSE_LIMIT_PRICE_INPUT,
  CLOSE_STOP_PRICE_INPUT,
  CLOSE_TIME_INPUT,
  CLOSE_DATE_INPUT,
} from 'shared-modules/constants/manualTrade';
import { formatNumberToDisplayedString, getFxCfdQuantityStep, getServiceQuantityUnit } from 'shared-modules/services';
import useDynamicClosePosition from 'shared-modules/services/hooks/closeOrderLogic';
import { useManualTradeSelectedInstrumentSettings } from 'shared-modules/services/hooks';
import { removeInstrumentIdSuffix } from 'shared-modules/utils';
import { MARKUPS_URL } from 'shared-modules/constants/markup';
import { useIsMarkupTarget } from 'shared-modules/hooks/markup';
import { openManualTradeTableCloseOrderConfirmation } from '../../../../redux/actions';
import Modal from '../../../../components/Modal';
import BuySellPricesForManual from '../../../../components/BuySellPricesForManual';
import CustomInput from '../../../../components/CustomInput';
import { InputNumber } from '../../../../components';
import CustomSelect from '../../../../components/CustomSelect';
import CustomCheckbox from '../../../../components/CustomCheckbox';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import CustomButton from '../../../../components/CustomButton';
import LabeledSwitch from '../../../../components/LabeledSwitch';
import HighlightValue from '../../../../components/HighlightValue/HighlightValue';
import styles from './manualTradeTableCloseOrder.module.scss';

const TIME_INPUT_WIDTH = 122;
const DATEPICKER_WIDTH = 131;
const INPUT_AND_DROPDOWN_WIDTH = 128;

const HighlightUnrealizedPLValue = memo(({ instrumentId, positionId }) => {
  const value = useSelector(
    (state) => state.currencies.positionsUnrealizedProfitLoss?.[instrumentId]?.[positionId]?.unrealizedProfitLoss,
  );

  return <HighlightValue value={value} isToFormat />;
});
HighlightUnrealizedPLValue.propTypes = {
  instrumentId: PropTypes.string,
  positionId: PropTypes.string,
};
HighlightUnrealizedPLValue.defaultProps = {
  instrumentId: '',
  positionId: '',
};

const ManualTradeTableCloseOrder = ({ isOpen, closeModal }) => {
  const serviceId = useSelector((state) => state.auth.serviceId);

  const { quantityPrecision } = useManualTradeSelectedInstrumentSettings();
  const isFX = useMemo(() => serviceId === FX, [serviceId]);
  const isETF = useMemo(() => serviceId === ETF, [serviceId]);
  const isCFD = useMemo(() => serviceId === CFD, [serviceId]);
  const quantityIsInteger = isETF && quantityPrecision >= 1;

  const closeOrderData = useDynamicClosePosition({
    isOpen,
    closeModal,
    openConfirmModal: openManualTradeTableCloseOrderConfirmation,
    allowUserDecimals: isFX,
  });

  const instrumentIdSymbol = isFX
    ? removeInstrumentIdSuffix(closeOrderData.displayedInstrumentId)
    : closeOrderData.displayedInstrumentId;
  const settings = useSelector((state) => state.settings.instrumentList?.[closeOrderData?.instrumentId]);

  const tooltipId = useRef(uuid()).current;

  const quantityUnit = getServiceQuantityUnit(serviceId);

  const orderType = useMemo(() => {
    if (closeOrderData?.paymentMethod === ORDER_TYPES_MAIN.MARKET_ORDER.ID) {
      return ORDER_METHOD_MAIN.MARKET.ID;
    }
    if (closeOrderData?.paymentMethod === ORDER_TYPES_MAIN.STANDARD.ID) {
      return closeOrderData?.orderMethod;
    }
    if (closeOrderData?.paymentMethod === ORDER_TYPES_MAIN.OCO.ID) {
      return ORDER_METHOD_MAIN.STOP_LIMIT.ID;
    }
    return null;
  }, [closeOrderData]);

  const isMarkupTarget = useIsMarkupTarget({
    instrumentId: closeOrderData?.instrumentId,
    orderType,
    orderAmount: closeOrderData?.count,
  });

  const renderMakeupsWarning = () => {
    if (!isMarkupTarget) {
      return null;
    }

    let message = '';
    const marketOrderMessage = '数量が100万通貨を超える場合、約定価格に大口マークアップが加算されます。詳しくは';
    const standardAndOcoMessage =
      '逆指値注文を設定し、かつ数量が100万通貨を超える場合、約定価格に大口マークアップが加算されます。詳しくは';

    if (closeOrderData.paymentMethod === ORDER_TYPES_MAIN.MARKET_ORDER.ID) {
      message = marketOrderMessage;
    } else if (
      closeOrderData.paymentMethod === ORDER_TYPES_MAIN.STANDARD.ID &&
      closeOrderData.orderMethod === ORDER_METHOD_MAIN.STOP_LIMIT.ID
    ) {
      message = standardAndOcoMessage;
    } else if (closeOrderData.paymentMethod === ORDER_TYPES_MAIN.OCO.ID) {
      message = standardAndOcoMessage;
    } else {
      return null;
    }

    return (
      <div className={styles.closeOrderCountRow}>
        <div className={styles.makeupsWarningRow}>
          <div className={styles.standardOrderInner}>
            {message}
            <a className={styles.button} href={MARKUPS_URL} target="_blank" rel="noopener noreferrer">
              こちら
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} title="決済注文" size={MODAL_SIZES.WIDTH_360}>
      <div className={styles.nameRow}>
        決済対象
        <span className={styles.rowValue} data-for={tooltipId} data-tip={closeOrderData.positionName}>
          {closeOrderData.positionName}
        </span>
        <ReactTooltip id={tooltipId} />
      </div>
      <div className={styles.instrumentRow}>
        銘柄
        <span>{instrumentIdSymbol}</span>
      </div>
      <div className={styles.transactionPriceRow}>
        取引価格
        <span className={styles.number}>
          {formatNumberToDisplayedString({ value: closeOrderData.tradePrice, withoutPlus: true, withYenIcon: false })}
        </span>
      </div>
      <div className={styles.countRow}>
        <div>
          {`発注可能数量(${quantityUnit})`}
          {isCFD && <div>{`1Lot=${settings?.quantityUnitConvFactor}`}</div>}
        </div>
        <span className={styles.number}>{closeOrderData.maxQuantity}</span>
      </div>
      <div className={styles.profitLossRow}>
        評価損益(円)
        <span className={styles.number}>
          <HighlightUnrealizedPLValue
            instrumentId={closeOrderData.instrumentId}
            positionId={closeOrderData.positionId}
          />
        </span>
      </div>
      <BuySellPricesForManual
        className={styles.buySellPrices}
        instrumentId={closeOrderData.instrumentId}
        buyPrice={closeOrderData.buyPrice}
        sellPrice={closeOrderData.sellPrice}
        previousSellPrice={closeOrderData.previousSellPrice}
        previousBuyPrice={closeOrderData.previousBuyPrice}
        isNotActive
      />
      <div className={styles.paymentMethodRow}>
        決済方式
        <LabeledSwitch
          activeItemId={closeOrderData.paymentMethod}
          onChange={closeOrderData.changePaymentMethod}
          options={OPTIONS_CLOSE_ORDER_TYPES}
        />
      </div>
      <div className={styles.settlementBuySellRow}>
        決済売買
        <LabeledSwitch
          activeItemId={closeOrderData.closeOrderSide}
          onChange={() => {}}
          options={OPTIONS_BUY_SELL}
          itemClassName={styles.itemClassName}
          showLockIcon
          isNotSelectable
        />
      </div>
      {closeOrderData.paymentMethod === ORDER_TYPES_MAIN.STANDARD.ID && (
        <div className={styles.orderMethodRow}>
          注文条件
          <LabeledSwitch
            activeItemId={closeOrderData.orderMethod}
            onChange={closeOrderData.changeOrderMethod}
            options={OPTIONS_ORDER_METHOD}
            itemClassName={styles.itemClassName}
          />
        </div>
      )}
      {Number(closeOrderData.tradeMethod) === TRADE_METHODS.MH_AP.ID ? (
        <div className={styles.closeOrderCountRow}>
          <div>
            {`発注可能数量(${quantityUnit})`}
            {isCFD && <div>{`1Lot=${settings?.quantityUnitConvFactor}`}</div>}
          </div>
          <span>{closeOrderData.count}</span>
        </div>
      ) : (
        <div className={styles.closeOrderCountRow}>
          <div>
            <div>{`数量(${quantityUnit})`}</div>
            {serviceId === CFD && <div>{`1Lot=${settings?.quantityUnitConvFactor}`}</div>}
          </div>
          <InputNumber
            value={closeOrderData.count}
            name={COUNT_INPUT}
            onChange={closeOrderData.changeCount}
            type="number"
            onlyIntegerAllowed={quantityIsInteger}
            width={INPUT_AND_DROPDOWN_WIDTH}
            max={closeOrderData.maxQuantity}
            step={isETF ? closeOrderData.dynamicQuantityStep : getFxCfdQuantityStep}
            errorMessages={closeOrderData.validationErrors}
            withErrorTooltip
          />
        </div>
      )}
      {renderMakeupsWarning()}

      {closeOrderData.paymentMethod === ORDER_TYPES_MAIN.STANDARD.ID && (
        <div className={styles.priceRow}>
          価格
          <InputNumber
            value={closeOrderData.closePrice}
            onChange={closeOrderData.changeClosePrice}
            type="number"
            width={INPUT_AND_DROPDOWN_WIDTH}
            step={closeOrderData.priceStep}
            name={CLOSE_PRICE_INPUT}
            errorMessages={closeOrderData.validationErrors}
            withErrorTooltip
          />
        </div>
      )}
      {closeOrderData.paymentMethod === ORDER_TYPES_MAIN.OCO.ID && (
        <>
          <div className={styles.limitPriceRow}>
            指値価格
            <InputNumber
              value={closeOrderData.closeLimitPrice}
              onChange={closeOrderData.changeCloseLimitPrice}
              type="number"
              width={INPUT_AND_DROPDOWN_WIDTH}
              step={closeOrderData.priceStep}
              name={CLOSE_LIMIT_PRICE_INPUT}
              errorMessages={closeOrderData.validationErrors}
              withErrorTooltip
            />
          </div>
          <div className={styles.stopPriceRow}>
            逆指値価格
            <InputNumber
              value={closeOrderData.closeStopPrice}
              onChange={closeOrderData.changeCloseStopPrice}
              type="number"
              width={INPUT_AND_DROPDOWN_WIDTH}
              step={closeOrderData.priceStep}
              name={CLOSE_STOP_PRICE_INPUT}
              errorMessages={closeOrderData.validationErrors}
              withErrorTooltip
            />
          </div>
        </>
      )}
      {closeOrderData.paymentMethod !== ORDER_TYPES_MAIN.MARKET_ORDER.ID && (
        <>
          <div className={styles.settlementExpirationTypeRow}>
            有効期限
            <CustomSelect
              selectItemId={closeOrderData.settlementExpirationType}
              onChange={closeOrderData.changeSettlementExpirationType}
              options={
                Number(closeOrderData.tradeMethod) !== TRADE_METHODS.MANUAL.ID
                  ? OPTIONS_NO_EXPIRATION_ONLY
                  : OPTIONS_EXPIRATION_TYPE
              }
              width={INPUT_AND_DROPDOWN_WIDTH}
            />
          </div>
          {closeOrderData.settlementExpirationType === EXPIRATION_TYPE_MAIN.CUSTOM.ID && (
            <>
              <div className={styles.datePickerRow}>
                日付
                <CustomDatePicker
                  width={DATEPICKER_WIDTH}
                  date={closeOrderData.selectedDate}
                  onChange={closeOrderData.setSelectedDate}
                  minDate={closeOrderData.currentDate}
                  popperPlacement="auto"
                  isTaller
                  disableSundays
                  name={CLOSE_DATE_INPUT}
                  errorMessages={closeOrderData.validationErrors}
                  withErrorTooltip
                />
              </div>
              <div className={styles.timePickerRow}>
                時刻
                <CustomInput
                  value={closeOrderData.selectedTime}
                  onChange={closeOrderData.onTimeChange}
                  width={TIME_INPUT_WIDTH}
                  placeholder="00:00"
                  isEndAligned
                  name={CLOSE_TIME_INPUT}
                  errorMessages={closeOrderData.validationErrors}
                  withErrorTooltip
                />
              </div>
            </>
          )}
        </>
      )}
      <CustomCheckbox
        isChecked={closeOrderData.withoutConfirmation}
        onChange={closeOrderData.handleChangeWithoutConfirmation}
        label="確認省略"
        isReversed
        className={styles.checkboxRow}
        labelClassName={styles.checkboxLabel}
      />
      <CustomButton
        color={COLORS.RED}
        width={WIDTH.PERCENTAGE_100}
        className={styles.button}
        onClick={closeOrderData.handleCloseOrder}
        isDisabled={closeOrderData.buttonIsDisabled}
        isLoading={closeOrderData.isLoadingCreateCloseOrder}
      >
        {closeOrderData.withoutConfirmation ? '注文確定' : '注文確認'}
      </CustomButton>
    </Modal>
  );
};

ManualTradeTableCloseOrder.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(ManualTradeTableCloseOrder);
